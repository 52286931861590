<!--Card do formulario-->
<div class="card p-3 col-8 mx-auto mt-5">
  <div class="card-body col-12">
    <h3>Registro de Animal</h3>

    <!--formulario-->
    <form class="row">

      <div class="d-flex justify-content-between col-12">

        <div class="form-group">
          <label for="name"><strong>Nome</strong></label>
          <input type="text" class="form-control" name="name" id="name" [(ngModel)]="form.name"
            placeholder="Digite o nome do animal" required>
        </div>

        <div class="form-group">
          <label for="formGroupExampleInput"><strong>Idade</strong></label>
          <input type="number" class="form-control" name="age" id="age" [(ngModel)]="form.age"
            placeholder="Digite a idade">
        </div>

        <div class="form-group">
          <label for="formGroupExampleInput"><strong>Raça</strong></label>
          <input type="text" class="form-control" name="breed" id="breed" [(ngModel)]="form.breed"
            placeholder="Digite a raça do animal">
        </div>
      </div>


      <div class="d-flex justify-content-between col-12  my-3">
        <div class="form-group">
          <label for="disabledSelect"><strong>Porte</strong></label>
          <select name="disabledSelect" id="disabledSelect" [(ngModel)]="form.size" class="form-control">
            <option disabled selected value="">Selecionar porte </option>
            <option value="SMALL">Pequeno</option>
            <option value="MEDIUM">Médio</option>
            <option value="BIG">Grande</option>
          </select>
        </div>

        <div class="form-group">
          <label for="disabledSelect"><strong>Sexo</strong></label>
          <select name="disabledSelect" id="disabledSelect" [(ngModel)]="form.sex" class="form-control">
            <option disabled selected value="">Selecionar sexo </option>
            <option value="MALE">Macho</option>
            <option value="FEMALE">Fêmea</option>
          </select>
        </div>
      </div>

      <div class="row">

        <div class="gender-inputs">
          <div class="gender-title">
            <h5><strong>Saúde</strong></h5>
          </div>
          <div class="gender-group">
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="checkbox" name="inlineCheckbox1" id="inlineCheckbox1"
                [(ngModel)]="vaccinated" value="option1">
              <label class="form-check-label" for="inlineCheckbox1"><strong>Vacinas</strong></label>
            </div>
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="checkbox" name="inlineCheckbox2" id="inlineCheckbox2"
                value="option2" [(ngModel)]="wormed">
              <label class="form-check-label" for="inlineCheckbox2"><strong>Vermífugos</strong></label>
            </div>
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="checkbox" name="inlineCheckbox3" id="inlineCheckbox3"
                value="option3" [(ngModel)]="dewormed">
              <label class="form-check-label" for="inlineCheckbox3"><strong>Desparasitação</strong></label>
            </div>
          </div>
        </div>

      </div>

      <div class="row">
        <label for="description"><strong>Descrição:</strong></label><br>
        <textarea name="description" id="description" [(ngModel)]="form.description" cols="70"
          placeholder="Digite uma descrição do animal" rows="4"></textarea>
      </div>

      <div class="d-flex my-3">
        <div class="form-group">
          <label for="exampleFormControlFile1"><strong>Fotos(Limite de 5, apenas jpg)</strong></label>
          <input type="file" class="form-control-file" id="exampleFormControlFile1" (change)="onFileSelected($event)"
          accept="image/jpeg, image/png, image/gif" multiple="multiple">
        </div>

        <div class="ms-auto form-check form-check-inline">
          <input class="form-check-input" type="checkbox" name="inlineCheckbox3" id="inlineCheckbox3"
            [(ngModel)]="form.available" value="option3">
          <label class="form-check-label" for="inlineCheckbox3"><strong>Disponível</strong></label>
        </div>
      </div>

      <div class="">
        <button (click)="validation()" type="submit" class="btn btn-primary mt-4">REGISTRAR</button>
      </div>
    </form>
  </div>
</div>

<!-- Search -->
<div class="input rounded col-8 mx-auto my-5">

  <!-- Bar -->
  <div class="input-group border rounded">

    <div class="form-outline">
      <input type="search" id="form1" class="form-control" [(ngModel)]="searchInput" (input)="searchNames()"
        (keyup.enter)="searchAnimals(searchInput)" />
      <label class="form-label" for="form1"><strong>Pesquisar por nome</strong></label>
    </div>

    <span class="input-text border-0 my-auto me-2 pointer" id="search-addon" (click)="searchAnimals(searchInput)">
      <i class="fas fa-search"></i>
    </span>

  </div>

  <!-- Drop list name -->
  <div *ngIf="animalNames.length > 0">
    <ul class="list-group border rounded">
      <li class="list-group-item list-group-item-action user-select-none pointer" *ngFor="let name of animalNames"
        (click)="searchAnimals(name)">
        {{ name }}
      </li>
    </ul>
  </div>

</div>

<!-- Animals -->

<div class="mx-auto px-auto col-12 row justify-content-center">

  <div class="mt-4 col-2 mx-3 text-center card" *ngFor="let animal of animals">

    <img class="card-img-top" src="https://dev-services.marinaadota.com.br/files/{{animal.googleDriveFileId}}.jpg"
      alt="Card image cap">

    <div class="card-body">

      <h5 class="mx-auto card-title">{{animal.name}}</h5>
      <p class="card-text">{{animal.age}}</p>
      <hr>

      <div class="ms-auto form-check form-check-inline">
        <input class="form-check-input" disabled type="checkbox" id="inlineCheckbox3" value="option3">
        <label class="form-check-label" for="inlineCheckbox3"><strong>Disponível</strong></label>
      </div>

      <div class="d-flex justify-content-center mt-3">
        <div class="mx-2" type="button">
          <button type="button" (click)="goToEdit(animal.id)" class="btn btn-outline-primary"><i
              class="fas fa-pencil text-primary"></i></button>
        </div>
        <div class="mx-2">
          <button type="button" class="btn btn-outline-danger" (click)="openDialog(animal.id, animal.name)">
            <i class="fas fa-trash text-danger"></i></button>
        </div>
      </div>


    </div>
  </div>

</div>

<div *ngIf="isLoading">
  <app-loading></app-loading>
</div>
