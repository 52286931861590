import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';



@Component({
  selector: 'app-remove-animal-dialog',
  templateUrl: './remove-animal-dialog.component.html',
  styleUrls: ['./remove-animal-dialog.component.css']

})
export class RemoveAnimalDialogComponent {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {}

}
