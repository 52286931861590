import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { finalize } from 'rxjs';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { UserService } from 'src/app/services/user.service';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit{

  public username: string;
  public password: string;
  public isLoading: boolean

  constructor(
    private userService: UserService,
    private authenticationService: AuthenticationService,
    private router: Router,
    private snackBar: MatSnackBar
    ) {
    this.username = "";
    this.password = "";
    this.isLoading = false;
  }

  ngOnInit(): void {
  }

  public login() {
    console.log(this.isLoading);
    this.isLoading = true;

    this.userService.login(this.username, this.password).pipe(
      finalize(() => this.isLoading = false)
    ).subscribe({
      next: response => {
        this.authenticationService.saveToken(response);
        this.router.navigate(["/dashboard"])
      },
      error: () => this.snackBar.open("Credenciais inválidas", "", { duration: 4000 })
    })
  }

}
