import { Component, OnInit } from '@angular/core';
import { AnimalService } from 'src/app/services/animal.service';
import { HealthInformation } from 'src/app/shared/model/healthInformation.enum';
import { RegistertAnimal } from 'src/app/shared/model/register-animal';
import { ReturnShortAnimal } from 'src/app/shared/model/return-short-animal';
import { Sex } from 'src/app/shared/model/sex.enum';
import { Size } from 'src/app/shared/model/size.enum';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { finalize } from 'rxjs';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { RemoveAnimalDialogComponent } from 'src/app/shared/remove-animal-dialog/remove-animal-dialog.component';


@Component({
  selector: 'app-add',
  templateUrl: './add.component.html',
  styleUrls: ['./add.component.css']
})
export class AddComponent implements OnInit {

  public animalNames: string[];
  public animals: ReturnShortAnimal[]
  public searchInput?: string;
  public form: RegistertAnimal;
  public vaccinated: boolean;
  public wormed: boolean;
  public dewormed: boolean;
  public isLoading: boolean;

  constructor(private animalService: AnimalService, private snackBar: MatSnackBar, private router: Router, public dialog: MatDialog) {
    this.animalNames = [];
    this.animals = []
    this.form = new RegistertAnimal("", 0, "", Sex.DEFAULT, 0, "", Size.DEFAULT, [], "", null, false);
    this.vaccinated = false
    this.wormed = false
    this.dewormed = false
    this.isLoading = false
  }

  ngOnInit(): void {
    this.searchAnimals()
  }

  searchNames(): void {

    if (this.searchInput && this.searchInput.trim().length > 0) {

      this.animalService.getAnimalNames(this.searchInput).subscribe({
        next: (data) => {
          console.log(data)
          this.animalNames = data
        },

        error: (error) => console.error(error)
      });

    } else {
      this.animalNames = [];
    }

  }

  searchAnimals(name?: string): void {

    this.searchInput = name

    this.isLoading = true

    this.animalService.getAnimals(name).pipe(
      finalize(() => this.isLoading = false)
    ).subscribe({
      next: (data) => {
        console.log(data);
        this.animals = data;
        this.animalNames = [];
      },

      error: (error) => console.log(error)
    })
  }

  onFileSelected(event: any) {
    this.form.files = Array.from(event.target.files)
  }

  public validation() {

    this.form.healthInformation = []

    if (this.vaccinated) {
      this.form.healthInformation.push(HealthInformation.VACCINATED)
    }

    if (this.wormed) {
      this.form.healthInformation.push(HealthInformation.WORMED)
    }

    if (this.dewormed) {
      this.form.healthInformation.push(HealthInformation.DEWORMED)
    }

    this.isLoading = true

    this.animalService.registerAnimals(this.form).pipe(
      finalize(() => this.isLoading = false)
    ).subscribe({

      next: (data) => {
        this.snackBar.open("Registrado com sucesso !", "", { duration: 4000 });
        this.searchAnimals();
      },

      error: (error) => this.snackBar.open("Erro inesperado", "", { duration: 4000 }),

    })

  }

  public goToEdit(id: number) {
    this.router.navigate([`/edit/${id}`]);
  }

  public removeAnimal(id: number) {

    console.log(id);

    this.animalService.deleteAnimal(id).subscribe({

      next: (id) => {
        this.snackBar.open("Animal removido !", "", { duration: 4000 });
        this.searchAnimals();
      },

      error: (error) => this.snackBar.open("Erro inesperado", "", { duration: 4000 }),

    })


  }

  openDialog(id: number, name: String) {
    const dialogRef = this.dialog.open(RemoveAnimalDialogComponent ,{ data: { name } } );
    

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
      if (result === true) {
       this.removeAnimal(id)  ;

      }
    });
  }

}


