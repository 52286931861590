import { Sex } from "./sex.enum";
import { Size } from "./size.enum";
import { HealthInformation } from "./healthInformation.enum";

export class RegistertAnimal{
    name: String;
    age: number;
    breed: String;
    sex: Sex;
    size: Size;
    healthInformation: HealthInformation[];
    description: String;
    files: File[] | null;
    available: boolean;

    constructor(name: String, id: number, url: String, sex: Sex, age: number, breed: String,
         size: Size, healthInformation: HealthInformation[], description: String, files: File[] | null, available : boolean){

        this.name = name;
        this.age = age;
        this.sex = sex;
        this.breed = breed;
        this.size = size;
        this.healthInformation = healthInformation;
        this.description = description;
        this.files = files;
        this.available = available;
    }
}
