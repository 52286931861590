import { HttpClient, HttpHandler, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../environments/environment';
import { ReturnShortAnimal } from '../shared/model/return-short-animal';
import { RegistertAnimal } from '../shared/model/register-animal';
import { AuthenticationService } from './authentication.service';


@Injectable({
  providedIn: 'root'
})
export class AnimalService {

  constructor(private httpClient: HttpClient, private authenticationService: AuthenticationService) { }

  public getAnimalNames(text: string): Observable<string[]> {
    return this.httpClient.get<string[]>(`${environment.API_URL}portal/show/names/${text}`);
  }

  public getAnimals(name?: string): Observable<ReturnShortAnimal[]> {

    let headers = new HttpHeaders();

    headers = headers.set ("Authentication", "Bearer " + this.authenticationService.takeToken())


    let params = new HttpParams();

    if (name) {
      params = params.set("name", name);
    }

    return this.httpClient.get<ReturnShortAnimal[]>(`${environment.API_URL}portal/short`, { headers, params });
  }

  public registerAnimals(newAnimal: RegistertAnimal): Observable<String> {

    const formData = new FormData();
    formData.append('name', newAnimal.name.toString());
    formData.append('age', newAnimal.age.toString());
    formData.append('breed', newAnimal.breed.toString());
    formData.append('size', newAnimal.size.toString());
    formData.append('sex', newAnimal.sex.toString());
    formData.append('description', newAnimal.description.toString());
    formData.append('available', newAnimal.available.toString())

    newAnimal.healthInformation.forEach(healthInformation => formData.append('healthInformation', healthInformation.toString()));

    newAnimal.files!.forEach(file => formData.append('files', file));

    return this.httpClient.post<any>(`${environment.API_URL}portal/register`, formData)
  }

  public getAnimalById(id: number): Observable<RegistertAnimal> {

    let headers = new HttpHeaders();

    headers = headers.set ("Authentication", "Bearer " + this.authenticationService.takeToken())

    return this.httpClient.get<RegistertAnimal>(`${environment.API_URL}portal/show/${id}`);
  }

  public edit(id: number, animal: RegistertAnimal): Observable<void> {

    const animalData = {
      id: id,
      name: animal.name,
      age: animal.age,
      breed: animal.breed,
      size: animal.size,
      sex: animal.sex,
      description: animal.description,
      available: animal.available,
      healthInformation: animal.healthInformation.map(info => info.toString())
    };
    const body = JSON.stringify(animalData);

    let headers = new HttpHeaders();
    headers = headers.set ("Authentication", "Bearer " + this.authenticationService.takeToken())
    headers = headers.set ("Content-Type", "application/json")

    return this.httpClient.put<void>(`${environment.API_URL}portal/animal`, body, { headers });

  }

  public deleteAnimal(id: number): Observable<string> {
    return this.httpClient.delete<string>(`${environment.API_URL}portal/${id}`);
  }

}
