import { NgModule } from '@angular/core';
import { LoadingComponent } from './loading/loading.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { RemoveAnimalDialogComponent } from './remove-animal-dialog/remove-animal-dialog.component';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';



@NgModule({
  declarations: [
    LoadingComponent,
    RemoveAnimalDialogComponent
  ],
  imports: [
    MatProgressSpinnerModule,
    MatDialogModule,
    MatButtonModule
  ],
  exports: [
    LoadingComponent,
    RemoveAnimalDialogComponent
  ]
})
export class SharedModule { }
