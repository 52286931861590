import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  constructor() { }

  public saveToken(token: string) {
    window.sessionStorage.setItem("token", token);
  }

  public takeToken() : string {
   return window.sessionStorage.getItem("token")!;
  }
}
