<!--Card do formulario-->
<div class="card p-3 col-8 mx-auto mt-5">
  <div class="d-flex justify-content-between">
    <a class="me-auto" routerLink="/dashboard"><i class="fas fa-arrow-left fa-lg"></i></a>
    <h2 class="me-auto">Edição de Animal</h2>
  </div>
  <div class="card-body col-12">
    <!--formulario-->
    <form class="row" (ngSubmit)="edit()">

      <div class="d-flex justify-content-between col-12">

        <div class="form-group">
          <label for="name"><strong>Nome</strong></label>
          <input type="text" class="form-control" name="name" id="name" [(ngModel)]="form.name"
            placeholder="Digite o nome do animal" required>
        </div>

        <div class="form-group">
          <label for="formGroupExampleInput"><strong>Idade</strong></label>
          <input type="number" class="form-control" name="age" id="age" [(ngModel)]="form.age"
            placeholder="Digite a idade" required>
        </div>

        <div class="form-group">
          <label for="formGroupExampleInput"><strong>Raça</strong></label>
          <input type="text" class="form-control" name="breed" id="breed" [(ngModel)]="form.breed"
            placeholder="Digite a raça do animal" required>
        </div>
      </div>


      <div class="d-flex justify-content-between col-12  my-3">
        <div class="form-group">
          <label for="size"><strong>Porte</strong></label>
          <select name="size" id="size" [(ngModel)]="form.size" class="form-control" required>
            <option disabled selected value="">Selecionar porte </option>
            <option value="SMALL">Pequeno</option>
            <option value="MEDIUM">Médio</option>
            <option value="BIG">Grande</option>
          </select>
        </div>

        <div class="form-group">
          <label for="sex"><strong>Sexo</strong></label>
          <select name="sex" id="sex" [(ngModel)]="form.sex" class="form-control" required>
            <option disabled selected value="">Selecionar gênero </option>
            <option value="MALE">Masculino</option>
            <option value="FEMALE">Feminino</option>
          </select>
        </div>
      </div>

      <div class="row">

        <div class="gender-inputs">
          <div class="gender-title">
            <h5><strong>Saúde</strong></h5>
          </div>

          <div class="gender-group">
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="checkbox" name="vaccinated" id="vaccinated" [(ngModel)]="vaccinated"
                value="vaccinated">
              <label class="form-check-label" for="vaccinated"><strong>Vacinas</strong></label>
            </div>
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="checkbox" name="wormed" id="wormed" value="wormed"
                [(ngModel)]="wormed">
              <label class="form-check-label" for="wormed"><strong>Vermífugos</strong></label>
            </div>
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="checkbox" name="dewormed" id="dewormed" value="dewormed"
                [(ngModel)]="dewormed">
              <label class="form-check-label" for="dewormed"><strong>Desparasitação</strong></label>
            </div>
          </div>

        </div>

      </div>

      <div class="row">
        <label for="description"><strong>Descrição:</strong></label><br>
        <textarea name="description" id="description" [(ngModel)]="form.description" cols="70"
          placeholder="Digite uma descrição do animal" rows="4" required></textarea>
      </div>

      <div class="d-flex my-3">
        <div class="ms-auto form-check form-check-inline">
          <input class="form-check-input" type="checkbox" name="available" id="available" [(ngModel)]="form.available"
            value="available" required>
          <label class="form-check-label" for="available"><strong>Disponível</strong></label>
        </div>
      </div>

      <div class="d-flex justify-content-between">
        <button class="btn btn-danger mt-4">Remover</button>
        <button type="submit" class="btn btn-primary mt-4">Editar</button>
      </div>
    </form>
  </div>
</div>
