
<mat-dialog-content class="mat-typography">
  <h3>Tem certeza de que deseja remover {{data.name}}</h3>
  
  
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Cancelar</button>
  <button mat-button [mat-dialog-close]="true" cdkFocusInitial>Remover</button>
</mat-dialog-actions>

