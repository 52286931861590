import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private http: HttpClient) { }

  public login(username: string, password: string): Observable<string> {
    return this.http.post<string>(`${environment.API_URL}user/login`, {username, password}, { responseType: 'text' as 'json' });
  }
}
