import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { AnimalService } from 'src/app/services/animal.service';
import { HealthInformation } from 'src/app/shared/model/healthInformation.enum';
import { RegistertAnimal } from 'src/app/shared/model/register-animal';
import { Sex } from 'src/app/shared/model/sex.enum';
import { Size } from 'src/app/shared/model/size.enum';

@Component({
  selector: 'app-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.css']
})
export class EditComponent implements OnInit{

  public id: number = 0;
  public form: RegistertAnimal;
  public vaccinated: boolean;
  public wormed: boolean;
  public dewormed: boolean;

  constructor(private animalService: AnimalService, private router: Router, private activatedRoute: ActivatedRoute, private snackBar: MatSnackBar) {

    this.activatedRoute.params.subscribe(params => {
      this.id = params['id'];
    });

    this.form = new RegistertAnimal("", 0,"", Sex.DEFAULT, 0, "", Size.DEFAULT, [], "", null, false) ;
    this.vaccinated = false
    this.wormed = false
    this.dewormed = false

  }

  ngOnInit(): void {
    this.searchAnimalById();
  }

  private searchAnimalById() {
    this.animalService.getAnimalById(this.id).subscribe({
      next: (data) => {
        this.form = data;

        if (this.form.healthInformation.includes(HealthInformation.VACCINATED)) {
          this.vaccinated = true;
        }
        if (this.form.healthInformation.includes(HealthInformation.WORMED)) {
          this.wormed = true;
        }
        if (this.form.healthInformation.includes(HealthInformation.DEWORMED)) {
          this.dewormed = true;
        }

      },
      error: (error) => this.snackBar.open("Erro inesperado", "", { duration: 4000 })

    })
  }

  public edit() {
    this.form.healthInformation = []

    if (this.vaccinated) {
      this.form.healthInformation.push(HealthInformation.VACCINATED)
    }

    if (this.wormed){
      this.form.healthInformation.push(HealthInformation.WORMED)
    }

    if (this.dewormed){
      this.form.healthInformation.push(HealthInformation.DEWORMED)
    }

    this.animalService.edit(this.id, this.form).subscribe({

      next: (data) => {
        this.snackBar.open("Editado com sucesso !", "", { duration: 4000 });
        this.router.navigate(['/dashboard']);
      },

      error: (error) => this.snackBar.open("Erro inesperado", "", { duration: 4000 }),

    })
  }

}
