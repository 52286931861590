<div style="height: 98vh;" class="row">

    <!-- Aqui e o lado esquerdo da pagina     -->
    <div class="my-auto col-8">
        <div class="col-9 mx-auto">
            <img src="assets/Instituto Marina Adota - Logotipo_logotipo_ima_super horizontal.svg">
        </div>
    </div>


    <!-- A partir daqui e o lado direito da pagina -->
    <div class="card h-100 col-4" style="background-color: #f8f8f8">
        <div class="col-10 mx-auto my-auto">
            <h5 class="mb-3 text-center mx-auto">Acessar Portal</h5>
            <form class="card-body">
                <div class="mb-3">
                    <label for="username" class="form-label">Usuário</label>
                    <input type="text" class="form-control" name="username" [(ngModel)]="username" placeholder="Digite o seu usuário" required>
                </div>
                <div class="mb-3">
                    <label for="password" class="form-label">Senha</label>
                    <input type="password" class="form-control" name="password" [(ngModel)]="password" placeholder="Digite a sua senha" required>
                </div>
                <button (click)="login()" type="submit" class="col-12 btn btn-primary">Entrar</button>
            </form>
        </div>
    </div>

    <div *ngIf="isLoading">
      <app-loading></app-loading>
    </div>
</div>
